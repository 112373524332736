import React from 'react'
import { graphql } from 'gatsby'
import ContainerWrapper from '../../components/ContainerWrapper'
import { Col, Row } from 'react-grid-system'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import dayjs from 'dayjs'
import styled from 'styled-components'
import usePrism from '../../hooks/use-prism'
import Seo from '../../components/SEO'
import { Rss, Link2 } from 'react-feather'
import { headlineToId } from '../../utils/links'
import { scroller } from 'react-scroll'
import WhatsNewRelease from '../../components/WhatsNewRelease'
import useIsUserLoggedin from '../../hooks/use-is-user-logged-in'
import { mergeAndSortChangelogAndReleases, releaseToTitle} from '../../utils/changelog'
import { useLocation } from '@gatsbyjs/reach-router'
import Tag from '../../components/Tag'
import Filter from '../../components/Filter'
import VersionChooser from "../../components/VersionChooser";
import {allReleaseVersions, getAllComponents} from "../../utils/versions";
import {useReducer, useEffect, useState} from "react";
import DateFilter from "../../components/DateFilter";
import {isDateInRange} from "../../utils/dates";

const LogArticle = styled.div`
  display: flex;
  flex-direction: column;
  h2 {
    margin-top: 1rem;
    a, a:visited {
      display: flex;
      align-items: center;
      color: #3f3f3f;
      text-decoration: none;
      font-weight: bold;

      svg {
        margin-left: 1rem;
        color: #CCCCCC;
      }
    }

    a:hover {
      content: '';
      text-decoration: underline;
      color: #3f3f3f;
      cursor: pointer;
      font-weight: bold;

      svg {
        color: #3f3f3f;
      }
    }
  }
`;

const EntryContainer = styled.div`
  display: flex;
  justify-content: space-between;


  @media (max-width: 1380px) {
    flex-direction: column;
  }
`;

const LeftDiv = styled.div`
  flex: 0 0 40%;
  margin-right: 1rem;

  @media (max-width: 1000px) {
    flex: 0 0 100%;
    margin-right: 0;
  }
`;

const RightDiv = styled.div`
  flex: 0 0 60%;

  summary {
    /* Remove default list style and cursor */
    list-style: none;
    cursor: pointer;
    position: relative;
    padding-left: 20px; /* Space for the triangle */
    margin-bottom: 1rem;
    font-size: 100%;
    font-weight: bold;
  }

  summary::before {
    content: '\\25B6'; /* Unicode for the right-pointing triangle */
    position: absolute;
    left: 0;
    top: 25%;
    font-size: 0.8em;
    line-height: 1.0em;
    transition: transform 0.2s ease-in-out;
  }

  details[open] summary::before {
    content: '\\25BC'; /* Unicode for the down-pointing triangle */
  }

  @media (max-width: 1000px) {
    flex: 0 0 100%;
  }
`;

const FiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 2rem
`;

const Style = styled.div`

  .log {
    > div {
    }
  }

  p {
    font-size: 18px;
  }


  .meta {

    font-size: 14px;
    text-transform: uppercase;

    .date {
      margin-bottom: 24px;
      color: #787878;
      line-height: 150%;
    }

    .chips {

      margin-bottom: 12px;

      > div {
        display: inline-block;
        color: #ffffff;
        background-color: #006cae;
        border-radius: 24px;
        padding: 1px 12px;
      }

      > div + div {
        margin-left: 6px;
      }
    }
  }

  .rss-link {
    display: flex;
    align-items: center;
    margin-bottom: 3rem;

    a {
      margin-left: 6px;
      text-decoration: underline;
      color: #006cae;
    }
  }

  .article {
    h2 {
      margin-top: 1rem;
      a, a:visited {
        display: flex;
        align-items: center;
        color: #3f3f3f;
        text-decoration: none;
        font-weight: bold;

        svg {
          margin-left: 1rem;
          color: #CCCCCC;
        }
      }

      a:hover {
        content: '';
        text-decoration: none;
        color: #3f3f3f;
        cursor: pointer;

        svg {
          color: #3f3f3f;
        }
      }
    }
  }

  .filter {
    margin-top: 0px;
    margin-bottom: 1rem;
    word-wrap: normal;

  }
`

const scrollerConfig = { smooth: true, offset: -130 }

const ColoredLine = ({ color }) => (
    <hr
        style={{
          color: color,
          backgroundColor: color,
          height: 2,
          margin: "15px 0px 0px 0px",
        }}
    />
);

const filterReducer = (state, action) => {
  switch (action.type) {
    case 'SET_COMPONENTS_CMCC':
      return { ...state, componentsCMCC: action.payload};
    case 'SET_COMPONENTS_CMEC':
      return { ...state, componentsCMEC: action.payload};
    case 'SET_VERSIONS':
      return { ...state, versions: action.payload };
    case 'SET_CLOUD':
      return { ...state, cloud: action.payload };
    case 'SET_TYPE':
      return { ...state, type: action.payload };
    case 'SET_DATE_FILTER_RANGE':
      return { ...state, dateRange: action.payload };
    default:
      return state;
  }
};



const filterTypes = {all: 'All', releases: 'Releases', changes: 'Changes'}
const cloudNames = {all: 'All', cmcc: 'CoreMedia Content Cloud', cmec: 'CoreMedia Engagement Cloud'}


const WatsNewEntry = ({node}) => {
  const anchor = headlineToId(node.frontmatter.title)
  const container = React.useRef()
  usePrism(container)
  return (
    <div>
      <EntryContainer ref={container}>
        <LeftDiv>
          <h2 id={anchor} style={{display: 'flex', alignItems: 'center'}}>
            <a href={`#${anchor}`} onClick={() => scroller.scrollTo(anchor, scrollerConfig)}>
              {node.frontmatter.title}
              <Link2 style={{marginLeft: '0.5rem', flexShrink: 0}}/>
            </a>
          </h2>
          <div className={'meta'}>
            <div>
              {node.frontmatter.cloud &&
                <Tag tagValue={node.frontmatter.cloud} fontSize={"100%"} backgroundColor={'#6FC3B8'}/>}
              {node.frontmatter.version &&
                <Tag tagValue={node.frontmatter.version} fontSize={"100%"} backgroundColor={'#6FC3B8'}/>}
              {node.frontmatter.component &&
                <Tag tagValue={node.frontmatter.component} fontSize={"100%"} backgroundColor={'#6FC3B8'}/>}
            </div>
            <div className={'date'}>
              {dayjs(node.frontmatter.modified).format('D. MMMM, YYYY')}
            </div>
          </div>
        </LeftDiv>
        <RightDiv>
            <div style={{marginTop: '1rem'}}>
              <MDXRenderer>{node.body}</MDXRenderer>
            </div>
        </RightDiv>
      </EntryContainer>
      <ColoredLine color="#CCC"/>
    </div>
  )
}

const AmpEntry = ({node, isLoggedIn}) => {
  const title = releaseToTitle(node.aep, node.amp)
  const anchor = headlineToId(title)
  const tagValueLong = node.aep.version + '.' + node.amp.version;
  return (
    <div>
      <EntryContainer>
      <LeftDiv>
          <h2 id={anchor}>
            <a href={`#${anchor}`} onClick={() => scroller.scrollTo(anchor, scrollerConfig)}>
              {title}
              <Link2/>
            </a>
          </h2>
          <div className={'meta'}>
            <div>
              <Tag tagValue={node.major} fontSize={"100%"} backgroundColor={'#6FC3B8'}/>
              <Tag tagValue={tagValueLong} fontSize={"100%"} backgroundColor={'#6FC3B8'}/>
            </div>
            <div className={'date'}>
              {dayjs(node.amp.released).format('D. MMMM, YYYY')}
            </div>
          </div>
        </LeftDiv>
        <RightDiv>
          <div style={{marginTop: '1rem'}}>
            <WhatsNewRelease node={node} isLoggedIn={isLoggedIn}/>
          </div>
        </RightDiv>
      </EntryContainer>
      <ColoredLine color="#CCC"/>
    </div>
  )
}



const Child = () => {
  const location = useLocation()
  React.useLayoutEffect(() => {
    if (location.hash) {
      scroller.scrollTo(location.hash.substr(1), scrollerConfig)
    }
  }, [location.hash])
  return '';
}

const WhatsNew = ({pageContext, data}) => {

  const handleFilterChange = (filterName, value) => {
    console.log("Im in handleFilterChange and the value is: " + value + " and the filterName is: " + filterName)
    dispatch({ type: filterName, payload: value });
  };



  const combinedEdges = React.useMemo(
    () => mergeAndSortChangelogAndReleases(
      [{major: 10, data: data.allCmcc10Release}, {major: 11, data: data.allCmcc11Release}, {
        major: 12,
        data: data.allCmcc12Release
      }],
      data.allMdx
    ),
    [data.allCmcc10Release, data.allMdx, data.allCmcc11Release, data.allCmcc12Release]
  )
  const filterComponentsCMCC = React.useMemo(() => getAllComponents(data.allMdx, 'cloud', 'cmcc'), [data.allMdx]);
  const filterComponentsCMEC = React.useMemo(() => getAllComponents(data.allMdx, 'cloud', 'cmec'), [data.allMdx]);
  const cmcc12Version = React.useMemo(() => allReleaseVersions(data.allCmcc12Release, 'cmcc-12'), [data.allCmcc12Release]);
  const cmcc11Version = React.useMemo(() => allReleaseVersions(data.allCmcc11Release, 'cmcc-11'), [data.allCmcc11Release]);
  const cmcc10Version = React.useMemo(() => allReleaseVersions(data.allCmcc10Release, 'cmcc-10'), [data.allCmcc10Release]);

  const allVersions = React.useMemo( () => [...cmcc12Version, ...cmcc11Version, ...cmcc10Version], [cmcc12Version, cmcc11Version, cmcc10Version]);
  const majorMinorMapping ={}
  majorMinorMapping[0] = 'CMCC 12'
  majorMinorMapping[cmcc12Version.length] = 'CMCC 11'
  majorMinorMapping[cmcc12Version.length + cmcc11Version.length] = 'CMCC 10'

  const componentMapping = {
    'cmcc': filterComponentsCMCC,
    'cmec': filterComponentsCMEC
  }

  const versionMapping = {
    '10': cmcc10Version,
    '11': cmcc11Version,
    '12': cmcc12Version
  }

  const initialFilterState = {
    componentsCMCC: [],
    componentsCMEC: [],
    versions: [],
    cloud: 'cmcc',
    type: 'all',
    dateRange: []
  };



  const [isLoggedIn] = useIsUserLoggedin()

  //New from ChatGPT
  const [filterState, dispatch] = useReducer(filterReducer, initialFilterState);
  const [filteredEdges, setFilteredData] = useState(combinedEdges);
  const [showChild, setShowChild] = useState(false);

  const location = useLocation();

  useEffect(() => {
    // Check if 'filter' is available in location.state, otherwise default to 'cmcc'
    const cloudFilter = location.state?.filter || 'cmcc';
    // Dispatch an action to update the cloud filter state
    dispatch({ type: 'SET_CLOUD', payload: cloudFilter });
  }, [location.state]);


  const {
    breadcrumb: {crumbs},
  } = pageContext

  // Wait until after client-side hydration to show
  useEffect(() => {
    setShowChild(true);
  }, []);

  useEffect(() => {
    console.log("Cloud Filter is: " +  filterState.cloud )
    console.log("CMCC Components Filter length is: " +  filterState.componentsCMCC.length + " and the components are: " + JSON.stringify(filterState.componentsCMCC))
    console.log("CMEC Components Filter length is: " +  filterState.componentsCMEC.length + " and the components are: " + JSON.stringify(filterState.componentsCMEC))
    console.log("Version Filter length is: " +  filterState.versions.length + " and the content is: " + JSON.stringify(filterState.versions))
    console.log("Date Filter is:" +  JSON.stringify(filterState.dateRange))
    console.log("Type Filter is: " +  filterState.type )
    const filtered = combinedEdges.filter(edge => {
      //return true

      if (filterState.cloud === 'cmcc') {
        console.log("I'm in the cmcc filter")
        const cloudMatch = !edge.node.frontmatter || edge.node.frontmatter.cloud === 'cmcc' ? true : false;
        const typeMatch = (!edge.node.frontmatter && (filterState.type === 'all' || filterState.type === 'releases')) || (edge.node.frontmatter && (filterState.type === 'all' || filterState.type === 'changes')) ? true : false;
        const componentsMatch = filterState.componentsCMCC.length === 0 || !edge.node.frontmatter || filterState.componentsCMCC.includes(edge.node.frontmatter?.component) ? true : false;
        const componentsFrontmatterMatch = !(filterState.componentsCMCC.length === 0) && !edge.node.frontmatter;
        const versionMatch = (filterState.versions.length === 0 || (!edge.node.frontmatter && filterState.versions.includes(edge.node.version)) || ( edge.node.frontmatter && filterState.versions.includes(edge.node.frontmatter.version))) ? true : false;

        return cloudMatch && typeMatch && componentsMatch && versionMatch && !componentsFrontmatterMatch;
      } else if (filterState.cloud === 'cmec') {
        console.log("I'm in the cmec filter")
        const cloudMatch = !edge.node.frontmatter || edge.node.frontmatter.cloud === 'cmcc' ? false : true;
        const componentsMatch = filterState.componentsCMEC.length === 0 || !edge.node.frontmatter || filterState.componentsCMEC.includes(edge.node.frontmatter?.component) ? true : false;
        const dateMatch = !edge.node.frontmatter || filterState.dateRange.length === 0 || isDateInRange(edge.node.frontmatter.releaseDate, filterState.dateRange) ? true : false;

        return cloudMatch && componentsMatch && dateMatch;
      } else {
        return true;
      }
    });

    setFilteredData(filtered);
  }, [filterState, combinedEdges]);

  return (
    <ContainerWrapper crumbs={crumbs} fluid={true}>
      <Seo
        title={`What's New in ${cloudNames[filterState.cloud]}` }
        description={'Highlights of our product releases and access to release webinar recordings and slide decks for community members'}
      />
      <Style>
        <Row>
          <Col xs={0} md={4} className={"filter"}>
            <div className={'rss-link'}>
              <Rss/><a href={'/whats-new-rss.xml'}>Subscribe via RSS Feed</a>
            </div>
            <h3>Filters</h3>
            <FiltersContainer>
              <div className={'filter'}>
                <Filter tagValue="Products" filterTypes={cloudNames} changeFunction={handleFilterChange}
                        filterName='SET_CLOUD' initialValue={filterState.cloud} inputType={"dropdown"}/>
              </div>
              {filterState.cloud === 'cmcc' && (
                <>
                  <div className={'filter'}>
                    <Filter tagValue="Releases or Changes" filterTypes={filterTypes} changeFunction={handleFilterChange}
                            filterName='SET_TYPE' initialValue={filterState.type} inputType={"dropdown"}/>
                  </div>

                  <div className={'filter'}>
                    <VersionChooser filterTypes={allVersions}
                                    changeFunction={handleFilterChange} filterName='SET_VERSIONS'
                                    initialValue={filterState.versions} versionMapping={majorMinorMapping}/>
                  </div>
                  <div className={'filter'}>
                    <Filter tagValue="Components" filterTypes={componentMapping[filterState.cloud]}
                            changeFunction={handleFilterChange}
                            filterName='SET_COMPONENTS_CMCC'
                            initialValue={filterState.componentsCMCC}
                            inputType={"checkbox"}/>
                  </div>
                </>
              )}
              {filterState.cloud === 'cmec' && (
                <>
                  <div className={'filter'}>
                    <Filter tagValue="Components" filterTypes={componentMapping[filterState.cloud]}
                            changeFunction={handleFilterChange}
                            filterName='SET_COMPONENTS_CMEC'
                            initialValue={filterState.componentsCMEC}
                            inputType={"checkbox"}/>
                  </div>
                  <div className={'filter'}>
                    <DateFilter onFilterChange={handleFilterChange}/>
                  </div>
                </>
              )}

            </FiltersContainer>
          </Col>
          <Col xs={12} md={8}>
            <h1>What's New in {cloudNames[filterState.cloud]}</h1>
            <p>
              Discover changelog highlights, release notes, webinars, and more for CoreMedia Content Cloud and
              Engagement
              Cloud updates. Please note that a&nbsp;
                <a href={'https://www.coremedia.com/support'}>CoreMedia Community login</a> is required
                to access the webinar recordings, slide decks and full release notes.
              </p>
              <ColoredLine color="#CCC"/>
              <LogArticle>
                {filteredEdges.map(({node}, index) => {
                  return node.amp
                    ? <AmpEntry node={node} isLoggedIn={isLoggedIn} key={index}/>
                    : <WatsNewEntry node={node} key={index}/>;
                })}
              </LogArticle>
              {showChild && <Child/>}
          </Col>
          </Row>
      </Style>
    </ContainerWrapper>
  )
}

export const Head = () =>
  <link rel="alternate" type="application/rss+xml"
        title="CoreMedia Content Cloud - What's New RSS Feed"
        href="/whats-new-rss.xml"/>

export default WhatsNew

export const query = graphql`
  {
    allMdx(
      filter: {fileAbsolutePath: {regex: "/.*\/changelog\/.*/"}}
      sort: {fields: frontmatter___modified, order: DESC}
    ) {
      edges {
        node {
          body
          frontmatter {
            title
            modified
            component
            version
            cloud
            majorVersion
            releaseDate
          }
        }
      }
    }
    allCmcc10Release {
      edges {
        node {
          aep {
            release_webinar_recording
            release_webinar_slides
            version
            whats_new_available
          }
          amp {
            components
            released
            version
          }
          id
        }
      }
    }
    allCmcc11Release {
      edges {
        node {
          aep {
            release_webinar_recording
            release_webinar_slides
            version
            whats_new_available
          }
          amp {
            components
            released
            version
          }
          id
        }
      }
    }
    allCmcc12Release {
      edges {
        node {
          aep {
            release_webinar_recording
            release_webinar_slides
            version
            whats_new_available
          }
          amp {
            components
            released
            version
          }
          id
        }
      }
    }
  }
        `
