import React, { useState } from 'react';
import styled from 'styled-components';

// Styled-components for styling
const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  .filter-buttons {
    display: flex;
    gap: 10px;
  }

  .filter-buttons-row {
    display: flex;
    gap: 10px;
    margin-top: 10px;
  }

  select {
    padding: 5px;
    font-size: 14px;
  }
  input[type="date"] + input[type="date"] {
    margin-left: 10px; /* Add margin only to the second date input */
  }
`;
const Button= styled.button `
  padding: 5px;
  border: 1px solid #000;
  background-color: #fff;
  color: #000;
  font-size: 16px;
  font-family: 'DM Sans', sans-serif;
  cursor: pointer;
  border-style: none none solid;


  &:focus {
    outline: none;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const DateFilter = ({ onFilterChange }) => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const filterName = 'SET_DATE_FILTER_RANGE'

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
    onFilterChange(filterName, [e.target.value, endDate]);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
    onFilterChange(filterName, [startDate, e.target.value]);
  };

  const selectLastDay = () => {
    const now = new Date();
    const lastDay = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
    setStartDate(lastDay.toISOString().split('T')[0]);
    setEndDate(now.toISOString().split('T')[0]);
    onFilterChange(filterName, [lastDay.toISOString().split('T')[0], now.toISOString().split('T')[0]]);
  };

  const selectLastWeek = () => {
    const now = new Date();
    const lastWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
    setStartDate(lastWeek.toISOString().split('T')[0]);
    setEndDate(now.toISOString().split('T')[0]);
    onFilterChange(filterName, [lastWeek.toISOString().split('T')[0], now.toISOString().split('T')[0]]);
  };

  const selectLastMonth = () => {
    const now = new Date();
    const lastMonth = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate());
    setStartDate(lastMonth.toISOString().split('T')[0]);
    setEndDate(now.toISOString().split('T')[0]);
    onFilterChange(filterName, [lastMonth.toISOString().split('T')[0], now.toISOString().split('T')[0]]);
  };

  const resetFilter = () => {
    setStartDate('');
    setEndDate('');
    onFilterChange(filterName, []);
  };

  return (
    <FilterContainer>
      <h4>Select Release Dates</h4>
      <div className="filter-dates">
        <input
          type="date"
          value={startDate}
          onChange={handleStartDateChange}
          placeholder="dd-mm-yyyy"
        />
        <input
          type="date"
          value={endDate}
          onChange={handleEndDateChange}
          placeholder="dd-mm-yyyy"
        />
      </div>
      <div className="filter-buttons">
        <Button onClick={selectLastDay}>Last Day</Button>
        <Button onClick={selectLastWeek}>Last Week</Button>
        <Button onClick={selectLastMonth}>Last Month</Button>
      </div>
      <div className='filter-buttons-row'>
        <Button onClick={resetFilter}>Reset</Button>
      </div>
    </FilterContainer>
  );
};

export default DateFilter;
